import React from "react";
import PropTypes from "prop-types";

import styles from "./IndexIntro.module.scss";
import Content from "./Content";

const propTypes = {
	data: PropTypes.object.isRequired,
};

function IndexIntro({ data }) {
	const { introImage, introList } = data;

	return (
		<section className={styles.wrapper__main}>
			<div className={styles.wrapper__content}>
				<section className={styles.wrapper__image}>
					<div className={styles.heroImage__container}>
						<img className={styles.heroImage__imgEl} src={introImage} alt="hero visual" />
					</div>
				</section>

				<section className={styles.wrapper__copy}>
					<Content introList={introList} />
				</section>
			</div>
		</section>
	);
}

IndexIntro.propTypes = propTypes;

export default IndexIntro;
