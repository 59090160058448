import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { isArray, isEmpty } from "lodash";
import { motion, AnimatePresence } from "framer-motion";

import Button from "../Button/Button";

import styles from "./Content.module.scss";

const animation = {
	inactive: {
		opacity: 0,
		transition: {
			duration: 1.35,
			ease: [0.5, 1, 0.89, 1],
		},
	},
	active: {
		opacity: 1,
		transition: {
			duration: 1.35,
			ease: [0.5, 1, 0.89, 1],
		},
	},
};

const propTypes = {
	introList: PropTypes.array,
};

const defaultProps = {
	introList: [],
};

function Content({ introList }) {
	const [activeIndex, setActiveIndex] = useState(0);
	const INTERVAL = 5;

	useEffect(() => {
		const interval = setInterval(() => {
			setActiveIndex((activeIndex) => (activeIndex < introList.length - 1 ? activeIndex + 1 : 0));
		}, INTERVAL * 1000);

		return () => {
			clearInterval(interval);
		};
	}, []);

	return (
		<>
			{isArray(introList) && !isEmpty(introList) && (
				<div className={styles.copy__container}>
					<AnimatePresence exitBeforeEnter>
						<motion.div
							key={activeIndex}
							initial={animation.inactive}
							animate={animation.active}
							exit={animation.inactive}
						>
							<h1 className={styles.copy__headerSmall}>We Craft</h1>
							<h1 className={styles.copy__headerBig}>{introList[activeIndex].intro_item_header}</h1>
							<h2 className={styles.copy__body}>{introList[activeIndex].intro_item_body}</h2>
							<Button
								text={introList[activeIndex].intro_item_cta_text}
								to={introList[activeIndex].intro_item_cta_link}
								className={styles.copy__btn}
							/>
						</motion.div>
					</AnimatePresence>
				</div>
			)}
		</>
	);
}

Content.propTypes = propTypes;
Content.defaultProps = defaultProps;

export default Content;
