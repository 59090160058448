import React, { useState } from "react";
import { Link } from "gatsby";
import PropTypes from "prop-types";
import { isEmpty } from "lodash";
import Slider from "react-slick";
import { motion, AnimatePresence } from "framer-motion";
import { truncateStr } from "../../utils";

import styles from "./IndexFeaturedWorks.module.scss";

const propTypes = {
	data: PropTypes.object.isRequired,
};

const copyVariants = (delay = 0) => {
	return {
		inactive: {
			opacity: 0,
			y: -20,
		},
		active: {
			opacity: 1,
			y: 0,
			transition: {
				duration: 0.85,
				delay,
				easing: [0.5, 1, 0.89, 1],
			},
		},
		exit: {
			opacity: 0,
		},
	};
};

function IndexFeaturedWorks({ data }) {
	const { featuredWorks, workData } = data;
	const [activeIndex, setActiveIndex] = useState(0);

	const filteredWorkList = [];
	featuredWorks.forEach((i) => {
		const filtered = workData.filter((filterItem) => {
			return (
				i.toLowerCase() ===
				filterItem.node.childMarkdownRemark.frontmatter.projectTitle.toLowerCase()
			);
		})[0];

		filteredWorkList.push(filtered);
	});

	return (
		<div className={styles.wrapper__main}>
			{!isEmpty(filteredWorkList) && (
				<>
					<Slider
						arrows={false}
						autoplay
						autoplaySpeed={10000}
						pauseOnHover={false}
						afterChange={(idx) => setActiveIndex(idx)}
					>
						{filteredWorkList.map((item) => {
							const {
								node: {
									childMarkdownRemark: {
										frontmatter: { projectHeroImage: heroImage, projectTitle: title },
										fields: { slug },
									},
								},
							} = item;

							return (
								// wrap href tag up or slick will bug out with href tag as parent wrapper
								<div className={styles.wrapper__carouselItem} key={title}>
									<img src={heroImage} alt={title} className={styles.backgroundImage} />
								</div>
							);
						})}
					</Slider>

					<section className={styles.wrapper__container}>
						{filteredWorkList.map((item, idx) => {
							const {
								node: {
									childMarkdownRemark: {
										frontmatter: {
											projectTitle: title,
											projectDescription: description,
											projectClientCompany: clientCompany,
										},
									},
								},
							} = item;

							return (
								<AnimatePresence key={title}>
									<div key={activeIndex} className={styles.wrapper__contentPlacer}>
										<motion.h1
											initial={copyVariants().inactive}
											animate={idx === activeIndex ? copyVariants().active : false}
											exit={copyVariants().exit}
											className={styles.title}
										>
											{title}
										</motion.h1>

										<motion.h2
											initial={copyVariants().inactive}
											animate={idx === activeIndex ? copyVariants(0.3).active : false}
											exit={copyVariants().exit}
											className={styles.body}
										>
											{truncateStr(description)}
										</motion.h2>

										{clientCompany && (
											<motion.p
												initial={copyVariants().inactive}
												animate={idx === activeIndex ? copyVariants(0.6).active : false}
												exit={copyVariants().exit}
												className={styles.company}
											>
												{clientCompany}
											</motion.p>
										)}
									</div>
								</AnimatePresence>
							);
						})}
					</section>
				</>
			)}
		</div>
	);
}

IndexFeaturedWorks.propTypes = propTypes;
export default IndexFeaturedWorks;
