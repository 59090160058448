import React from "react";
import { StaticQuery, graphql } from "gatsby";
import uniqid from "uniqid";

import styles from "./Capabilities.module.scss";

function Capabilities() {
	return (
		<StaticQuery
			query={graphql`
				query {
					data: file(name: { eq: "index" }) {
						id
						childMarkdownRemark {
							frontmatter {
								capabilities_list {
									capabilities_item_body
									capabilities_item_image
									capabilities_item_title
								}
							}
						}
					}
				}
			`}
			render={({
				data: {
					childMarkdownRemark: {
						frontmatter: { capabilities_list: listData },
					},
				},
			}) => (
				<div key={uniqid()} className={styles.wrapper__main}>
					<div className={styles.wrapper__content}>
						<h2 className={styles.sectionTitle}>OUR CAPABILITIES</h2>

						<div className={styles.wrapper__grid}>
							{listData.map((item) => {
								const {
									capabilities_item_image: image,
									capabilities_item_title: title,
									capabilities_item_body: body,
								} = item;

								return (
									<div key={uniqid()} className={styles.grid__colWrapper}>
										<>
											{image && (
												<div className={styles.grid__imgWrapper}>
													<img src={image} alt={title} className={styles.grid__imgEl} />
												</div>
											)}
											<div className={styles.grid__copyWrapper}>
												<h1 className={styles.grid__title}>{title}</h1>
												<p className={styles.grid__body}>{body}</p>
											</div>
										</>
									</div>
								);
							})}
						</div>
					</div>
				</div>
			)}
		/>
	);
}

export default Capabilities;
