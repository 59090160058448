import React, { PureComponent } from "react";
import { graphql } from "gatsby";
import PropTypes from "prop-types";

import IndexFeaturedWorks from "../components/IndexFeaturedWorks/IndexFeaturedWorks";
import IndexIntro from "../components/IndexIntro/IndexIntro";
import Capabilities from "../components/Capabilities/Capabilities";
import Clients from "../components/Clients/Clients";
import SEO from "../components/SEO/SEO";

const propTypes = {
	data: PropTypes.object.isRequired,
};

class Index extends PureComponent {
	render() {
		const { data } = this.props;
		const {
			index: {
				childMarkdownRemark: {
					frontmatter: {
						intro_image: introImage,
						intro_list: introList,
						index_seo_title: seoTitle,
						index_seo_description: index_seo_description,
					},
				},
			},
		} = data;

		return (
			<>
				<SEO title={seoTitle} description={index_seo_description} />
				{/* <IndexFeaturedWorks data={{ featuredWorks, workData }} /> */}
				<IndexIntro data={{ introImage, introList }} />
				<Capabilities />
				<Clients />
			</>
		);
	}
}

Index.propTypes = propTypes;
export default Index;

export const query = graphql`
	query {
		index: file(name: { eq: "index" }) {
			id
			childMarkdownRemark {
				frontmatter {
					index_seo_description
					index_seo_title
					featured_works
					intro_image
					intro_list {
						intro_item_body
						intro_item_cta_link
						intro_item_cta_text
						intro_item_header
					}
				}
			}
		}

		works: allFile(filter: { relativeDirectory: { eq: "work" } }) {
			edges {
				node {
					name
					childMarkdownRemark {
						frontmatter {
							projectTitle
							projectDescription
							projectHeroImage
							projectClientCompany
						}
						fields {
							slug
						}
					}
				}
			}
		}
	}
`;
